<template>
  <v-main>
    <v-container fluid>
      <!-- Menus de dia -->
      <h3 class="headline mb-4" v-if="daily_menus.length > 0">
        {{ $t('home.menu') }}
      </h3>

      <v-row>
        <v-col
          cols="12"
          sm="6"
          lg="4"
          xl="3"
          v-for="daily_menu in daily_menus"
          :key="'daily-menu' + daily_menu.id"
        >
          <v-card
            class="mx-auto category"
            ripple
            @click="routeToMenu(daily_menu.id)"
          >
            <v-img
              class="white--text"
              height="170px"
              :src="daily_menu.image_path"
            >
              <v-card-title class="align-end fill-height pa-0">
                <div class="title pa-3 text-truncate">
                  {{ daily_menu.name | translate($i18n) }}
                </div>
              </v-card-title>
            </v-img>
          </v-card>
        </v-col>
      </v-row>

      <!-- Categories -->
      <h3 class="headline my-4" :class="daily_menus.length > 0 ? 'mt-10' : ''">
        {{ $t('home.categories') }}
      </h3>

      <v-row>
        <v-col
          cols="12"
          sm="6"
          lg="4"
          xl="3"
          v-for="category in categories"
          :key="'category' + category.id"
        >
          <v-card class="mx-auto category" ripple @click="routeTo(category.id)">
            <v-img
              class="white--text"
              height="170px"
              :src="category.image_path"
            >
              <v-card-title class="align-end fill-height pa-0">
                <div class="title pa-3 text-truncate">
                  {{ category.name | translate($i18n) }}
                </div>
              </v-card-title>
            </v-img>
          </v-card>
        </v-col>
      </v-row>

      <easy-serve-about-us></easy-serve-about-us>
    </v-container>
  </v-main>
</template>

<script>
import EasyServeAboutUs from '../components/EasyServeAboutUs';
import { mapGetters } from 'vuex';

export default {
  components: {
    EasyServeAboutUs,
  },

  data: () => ({}),

  computed: {
    ...mapGetters('restaurant', {
      categories: 'categories',
      daily_menus: 'daily_menus',
    }),
  },

  methods: {
    routeToMenu(daily_menu) {
      this.$router.push({
        name: 'daily-menu',
        params: { daily_menu: daily_menu },
      });
    },

    routeTo(category) {
      this.$router.push({ name: 'category', params: { category: category } });
    },
  },
};
</script>

<style></style>
